import "./App.css";
import React, { Component, lazy, Suspense } from "react";
import Sidebar from "./components/sidemenu";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import { getCurrentUser } from "./services/loginservice";
import ProtectedRoute from "./components/ProtectedRoute";

import Navbar from "./components/navbar";
import Loading from "./components/loading";
const Dashboard = lazy(() => import("./pages/dashboard"));
const LoginPage = lazy(() => import("./pages/loginpage"));
const Register = lazy(() => import("./pages/registerpage"));
const Logout = lazy(() => import("./pages/logout"));
const Brands = lazy(() => import("./pages/brands"));
const Products = lazy(() => import("./pages/products"));
const SubProducts = lazy(() => import("./pages/subproducts"));
const UsersBrands = lazy(() => import("./pages/users_brands"));
const UsersStores = lazy(() => import("./pages/users_stores"));
const Audit = lazy(() => import("./pages/audit"));
const Stores = lazy(() => import("./pages/stores"));
const UsersEventsPage = lazy(() => import("./pages/users_events"));
const Events = lazy(() => import("./pages/events"));
const NotFound = lazy(() => import("./pages/notfound"));
const MyMap = lazy(() => import("./pages/mappage"));

class App extends Component {
  state = {};

  componentDidMount() {
    const user = getCurrentUser();
    this.setState({ user });
  }

  /*componentDidUpdate(prevProps, prevState) {
    if (prevState.user !== this.state.user) {
      console.log("user updated");
    }
  }*/

  render() {
    const { user } = this.state;
    return (
      <>
        <ToastContainer />
        <Navbar user={user} />
        <Sidebar>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route
                path="/login"
                render={(props) => <LoginPage {...props} />}
              />
              <Route path="/logout" render={(props) => <Logout {...props} />} />
              <ProtectedRoute path="/register" component={Register} />
              <ProtectedRoute path="/dashboard" component={Dashboard} />
              <ProtectedRoute path="/brands" component={Brands} />
              <ProtectedRoute path="/products" component={Products} />
              <ProtectedRoute path="/subproducts" component={SubProducts} />
              <ProtectedRoute path="/usersbrands" component={UsersBrands} />
              <ProtectedRoute path="/usersstores" component={UsersStores} />
              <ProtectedRoute path="/audit" component={Audit} />
              <ProtectedRoute path="/stores" component={Stores} />
              <ProtectedRoute path="/usersevents" component={UsersEventsPage} />
              <ProtectedRoute path="/events" component={Events} />
              <ProtectedRoute path="/map" component={MyMap} />
              <Route
                path="/not-found"
                render={(props) => <NotFound {...props} />}
              />
              <Redirect from="/" exact to="/dashboard" />
              <Redirect to="/not-found" />
            </Switch>
          </Suspense>
        </Sidebar>
      </>
    );
  }
}

export default App;
