import auth from "./httpservices.js";
import jwtDecode from "jwt-decode";
import { url } from "../services/baseurl";

auth.setJwt(getToken());

async function allusers() {
  const { data: users } = await auth.get(url + "users");
  return users;
}

async function deleteuser(id) {
  const { data: user } = await auth.delete(url + "users/" + id);
  return user;
}

async function register(user) {
  const { data: response } = await auth.post(url + "users", user);
  return response;
}

async function statusers() {
  const { data: cusers } = await auth.get(url + "users/statusers");
  return cusers;
}

async function countUsers() {
  const { data: numbersers } = await auth.get(url + "users/nusers");
  return numbersers;
}

async function login(email, password) {
  const { data: jwt } = await auth.post(url + "auth", { email, password });
  localStorage.setItem("token", jwt);
}

function logout() {
  localStorage.removeItem("token");
}

function getCurrentUser() {
  try {
    const token = localStorage.getItem("token");
    const user = jwtDecode(token);
    return user;
  } catch (error) {
    return null;
  }
}

function getToken() {
  return localStorage.getItem("token");
}

export {
  login,
  logout,
  getCurrentUser,
  register,
  getToken,
  allusers,
  deleteuser,
  countUsers,
  statusers,
};
