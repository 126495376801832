import React, { useState, memo } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../services/loginservice";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "fa fa-tachometer",
    },
    {
      path: "/brands",
      name: "Brands",
      icon: "fa fa-list",
    },
    {
      path: "/products",
      name: "Products",
      icon: "fa fa-product-hunt",
    },
    {
      path: "/subproducts",
      name: "SubProducts",
      icon: "fa fa-caret-down",
    },
    {
      path: "/usersbrands",
      name: "UsersBrands",
      icon: "fa fa-user-plus",
    },
    {
      path: "/usersstores",
      name: "UsersStores",
      icon: "fa fa-ship",
    },
    {
      path: "/register",
      name: "NewUser",
      icon: "fa fa-user-plus",
      allowed: true,
    },
    {
      path: "/audit",
      name: "Audit",
      icon: "fa fa-calculator",
    },
    {
      path: "/stores",
      name: "Stores",
      icon: "fa fa-cart-plus",
    },
    { path: "/usersevents", name: "UsersEvents", icon: "fa fa-calendar-o" },
    {
      path: "/events",
      name: "Events",
      icon: "fa fa-user-plus",
    },
    {
      path: "/map",
      name: "Map",
      icon: "fa fa-map",
    },
  ];
  return (
    <div className="parent h-100 container-fluid d-flex m-auto overflow-hidden">
      {getCurrentUser()?.isOrg === 0 && (
        <div
          style={{
            width: isOpen ? "200px" : "50px",
            top: 5,
            left: 5,
            height: 750,
            position: "fixed",
            zIndex: 99,
          }}
          className="sidebar"
        >
          <div className="top_section">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
              <img
                style={{ borderRadius: "50%" }}
                width="80"
                height="80"
                src={require("../assets/logo.png")}
                alt="logo"
              />
            </h1>
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars style={{ cursor: "pointer" }} onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <Link to={item.path} key={index} className="link">
              <div className="icon">
                <i className={item.icon} aria-hidden="true"></i>
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                {item.name}
              </div>
            </Link>
          ))}
        </div>
      )}
      <div
        style={
          isOpen
            ? {
                width: "100%",
                height: "100%",
                marginLeft: 200,
                transition: "all 0.3s ease",
              }
            : {
                width: "100%",
                height: "100%",
                transition: "all 0.3s ease",
              }
        }
      >
        {children}
      </div>
    </div>
  );
};

export default memo(Sidebar);
