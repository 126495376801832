import React, { useState, memo } from "react";
import { Link } from "react-router-dom";

function Navbar({ user }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [rotate, setRotate] = useState(0);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div className="container">
      <nav
        style={{
          backgroundColor: "#f1f4f8",
          marginTop: 10,
          borderRadius: 10,
          width: "100%",
        }}
        className="navbar navbar-expand-lg navbar-light"
      >
        <Link className="navbar-brand" to="/dashboad">
          <img
            width="50"
            height="50"
            src={require("../assets/logo.png")}
            alt="logo"
          />
        </Link>

        <button
          style={{
            transition: "0.5s ease-in 0.5s",
            transform: "rotate(" + rotate + ")",
          }}
          onMouseEnter={() => setRotate("360deg")}
          onMouseLeave={() => setRotate(0)}
          onClick={handleNavCollapse}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}>
          <ul className="navbar-nav d-flex align-items-center">
            {user?.isAdmin === 1 ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    Dashboad
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/brands">
                    Brands
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/products">
                    Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/subproducts">
                    Subproducts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/usersbrands">
                    Usersbrands
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/usersstores">
                    usersstores
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/usersevents">
                    UsersEvents
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/events">
                    Events
                  </Link>
                </li>
              </>
            ) : null}

            {!user && (
              <>
                <li className="nav-item">
                  <Link className="nav-link disabled" to="/login">
                    Login
                  </Link>
                </li>
              </>
            )}
            {user && (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/audit">
                    Audit
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/stores">
                    Stores
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/audit">
                    {user?.fullname.slice(0, 10)}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link disabled" to="/logout">
                    Logout
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default memo(Navbar);
